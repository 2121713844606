<template>
  <template v-for='(item, index) in menuData' :key="index">
    <el-menu-item v-if="!item.children || item.children.length === 0" :index="item.path">{{ item.title }}</el-menu-item>
    <el-sub-menu v-if="item.children && item.children.length !== 0" :index="item.path">
      <template #title>{{ item.title }}</template>
      <Header :menuData="item.children"></Header>
    </el-sub-menu>
  </template>
</template>

<script>
export default {
  name:'layoutHeader',
  props: {
    menuData: {
      type: Array,
    },
  }
}
</script>
<style scoped>
</style>
