import defaultSettings from '@/settings'

const title = defaultSettings.title || '柳林商学院'

export default function getPageTitle(pageTitle) {
  if (pageTitle && pageTitle !== "首页") {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}
