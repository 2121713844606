<template>
  <template v-for='(item, index) in menuData' :key="index">
    <div class="menu-span">
      <el-link :href="item.path">{{ item.title }}</el-link>
      <template v-if="item.children && item.children.length !== 0">
        <div class="menu-span-item">
          <Footer :menuData="item.children"></Footer>
        </div>
      </template>
    </div>
  </template>
</template>

<script>
export default {
  name: "layoutFooter",
  props: {
    menuData: {
      type: Array,
    }
  }
}
</script>

<style scoped>
.menu-span{
  display: inline-block;
  min-width: 6rem;
  text-align: left;
  vertical-align: top;
}
.menu-span-item .menu-span{
  display: block;
  padding: 0.5rem 0;
}
</style>