import {createRouter, createWebHistory} from 'vue-router'

import Layout from "@/views/layout";

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        name: 'Index',
        meta: { title: '首页', icon: 'table' },
        component: () => import("@/views/index"),
      },
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: 'corporate-training',
        name: 'customPrograms',
        meta: { title: '企业内训', icon: 'table' },
        component: () => import("@/views/corporate-training"),
      },
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: 'consulting',
        name: 'enterpriseConsulting',
        meta: { title: '企业咨询', icon: 'table' },
        component: () => import("@/views/enterprise-consulting"),
      },
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: 'elegant-demeanor',
        name: 'elegantDemeanor',
        meta: { title: '柳林风采', icon: 'table' },
        component: () => import("@/views/elegant-demeanor"),
      },
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: 'about-us',
        name: 'aboutUs',
        meta: { title: '关于我们', icon: 'table' },
        component: () => import("@/views/about-us"),
      },
    ]
  },
  {
    path: '/',
    children: [
      {
        path: 'event',
        name: 'event',
        meta: { title: '柳林买单“fun”肆学，9月免费抽学习啦！', icon: 'table' },
        component: () => import("@/views/event"),
      },
    ]
  },
]

//创建路由
const router = createRouter({
  //createWebHashHistory hash模式路径前面会多一个#号
  mode: 'history', // require service support
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
})

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // 重置路由器
}

//返回了路由
export default router
