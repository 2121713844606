import router from './router'
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' // 进度条样式
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async(to, from, next) => {
    // 开始进度条
    NProgress.start()

    // set page title
    document.title = getPageTitle(to.meta.title)

    next()
})

router.afterEach(() => {
    // finish 进度条
    NProgress.done()
})
