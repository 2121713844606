import axios from 'axios'
import { ElMessage  } from 'element-plus'
import store from '@/store'



// 创建一个axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // 当跨域请求时发送cookie
  timeout: 5000 // 请求超时
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么

    if (store.getters.token) {
      // 让每个请求都带有令牌
      // ['X-Token'] 是自定义标题键
      // 请根据实际情况修改
      //config.headers['Authorization'] = 'Bearer '
    }
    return config
  },
  error => {
    // 处理请求错误
    //console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  /**
   * 如果您想获得http信息，如headers或status
   * 请将  response => response
  */

  /**
   * 通过自定义代码响应拦截器确定请求状态
   * 这里只是一个例子
   * 您也可以通过HTTP状态码来判断状态
   */
  response => {
    const res = response.data
    // code为非200是抛错 可结合自己业务进行修改
    if (res.code && res.code !== 200) {
      ElMessage({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // 用于调试
    ElMessage({
      message: error.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
